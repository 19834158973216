// Subject List
export const FETCH_SUBJECT_LIST_BEGIN = 'FETCH_SUBJECT_LIST_BEGIN';
export const FETCH_SUBJECT_LIST_SUCCESS = 'FETCH_SUBJECT_LIST_SUCCESS';
export const FETCH_SUBJECT_LIST_FAILURE = 'FETCH_SUBJECT_LIST_FAILURE';

// Subjects
export const GET_SUBJECT_BEGIN = 'GET_SUBJECT_BEGIN';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_FAILURE = 'GET_SUBJECT_FAILURE';
export const REMOVE_SUBJECT = 'REMOVE_SUBJECT';
export const CHANGE_SUBJECT_COLOR = 'CHANGE_SUBJECT_COLOR';

// View Subject Information
export const VIEW_SUBJECT = 'VIEW_SUBJECT';
export const STOP_VIEWING_SUBJECT = 'STOP_VIEWING_SUBJECT';

// Clubs
export const GET_SPONSORS_BEGIN = 'GET_SPONSORS_BEGIN';
export const GET_SPONSORS_SUCCESS = 'GET_SPONSORS_SUCCESS';
export const GET_SPONSORS_FAILURE = 'GET_SPONSORS_FAILURE';

// Optimisations
export const SET_TIME_RANGE = 'SET_TIME_RANGE';
export const ADD_AVOID_DAY = 'SET_AVOID_DAY';
export const REMOVE_AVOID_DAY = 'REMOVE_AVOID_DAY';
export const SET_SKIP_LECTURES = 'SET_SKIP_LECTURES';
export const SET_CRAM_CLASSES = 'SET_CRAM_CLASSES';
export const SET_BREAK = 'SET_BREAK';
export const SET_MINIMISE_CLASHES = 'SET_MINIMISE_CLASHES';
export const SET_KEEP_CLASSES_STREAMED = 'SET_KEEP_CLASSES_STREAMED';
export const SET_IGNORE_WEIRD_STREAMS = 'SET_IGNORE_WEIRD_STREAMS';
export const UPDATE_POSSIBILITIES = ' UPDATE_POSSIBILITIES';
export const SET_DELIVERY_PREFERENCE = 'SET_DELIVERY_PREFERENCE';

// Optimiser
export const BEGIN_OPTIMISATION = 'BEGIN_OPTIMISATION';
export const COMPLETE_OPTIMISATION = 'COMPLETE_OPTIMISATION';
export const FAIL_OPTIMISATION = 'FAIL_OPTIMISATION';

// Timetables
export const NEXT_TIMETABLE = 'NEXT_TIMETABLE';
export const PREVIOUS_TIMETABLE = 'PREVIOUS_TIMETABLE';

// Custom Timetables
export const UPDATE_CUSTOM_TIMETABLE = 'UPDATE_CUSTOM_TIMETABLE';
export const CREATE_CUSTOM_TIMETABLE = 'CREATE_CUSTOM_TIMETABLE';

// Update Timetable
export const UPDATE_TIMETABLE = 'UPDATE_TIMETABLE';

// Timetable Viewer View
export const VIEW_GENERATED_TIMETABLES = 'VIEW_GENERATED_TIMETABLES';
export const VIEW_CUSTOM_TIMETABLES = 'VIEW_CUSTOM_TIMETABLES';

// Reserved time slots
export const ADD_RESERVED = 'ADD_RESERVED';
export const REMOVE_RESERVED = 'REMOVE_RESERVED';

// Global Theme Switching
export const SET_THEME = 'SET_THEME';

// Timetable Viewer Events
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
